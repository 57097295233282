import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "refactor-your-components-with-react-hooks"
    }}>{`Refactor your components with React Hooks`}</h1>
    <p>{`Hooks launched with great fanfare and community excitement in February 2019. A
whole new way to write React components.`}</p>
    <p>{`Not a fundamental change, the React team would say, but a mind shift after all.
A change in how you think about state, side effects, and component structure.
You might not like hooks at first. With a little practice they're going to feel
more natural than the component lifecycle model you're used to now.`}</p>
    <p>{`Hooks exist to:`}</p>
    <ul>
      <li parentName="ul">{`help you write less code for little bits of logic`}</li>
      <li parentName="ul">{`help you move logic out of your components`}</li>
      <li parentName="ul">{`help you reuse logic between components`}</li>
    </ul>
    <p>{`Some say it removes complexity around the `}<inlineCode parentName="p">{`this`}</inlineCode>{` keyword in JavaScript and I'm
not sure that it does. Yes, there's no more `}<inlineCode parentName="p">{`this`}</inlineCode>{` because everything is a
function, that's true. But you replace that with passing a lot of function
arguments around and being careful about function scope.`}</p>
    <p>{`My favorite React Hooks magic trick is that you can and should write your own.
Reusable or not, a custom hook almost always cleans up your code.`}</p>
    <p>{`Oh and good news! Hooks are backwards compatible. You can write new stuff with
hooks and keep your old stuff unchanged. 👌`}</p>
    <p>{`In this section we're going to look at the core React hooks, talk about the
most important hook for dataviz, then refactor our big example from earlier to
use hooks.`}</p>
    <p>{`You'll need to upgrade React to at least version `}<inlineCode parentName="p">{`16.8.0`}</inlineCode>{` to use hooks.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      